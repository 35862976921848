import Vue from 'vue';
import {
  Button,
  Field,
  DropdownMenu,
  DropdownItem,
  TreeSelect,
  List,
  ImagePreview,
  Swipe,
  SwipeItem
} from 'vant';

Vue.use(Button)
  .use(Field)
  .use(DropdownMenu)
  .use(DropdownItem)
  .use(TreeSelect)
  .use(ImagePreview)
  .use(Swipe)
  .use(SwipeItem)
  .use(List);
