<template>
  <router-view id="app" v-if="isRouterAlive" />
</template>

<script>
// import { scaleListener } from '../src/plugins/resize'
import { ipLocationCity } from 'services/common.js'
import { isLogin, userInfo } from 'services/login';
export default {
  name: 'app',
  provide () {
    return {
      reload: this.reload
    }
  },

  data () {
    return {
      isRouterAlive: true,
      mustLoginArr: ['/collectionHousing', '/lookHouseSubscribe', '/myRate', '/userInfo']
    }
  },
  mounted () {
    this.initMap()
    window.onbeforeunload = function () {
      console.log('刷新页面')
      sessionStorage.ticket = ''
    }

    // 判断token是否失效
    if (!localStorage.token && !sessionStorage.ticket)
      var ti = setTimeout(() => {
        if (this.mustLoginArr.indexOf(this.$route.path) === -1) {
          window.location.href = window.loginIp + "/#/ticket?from=" + window.location.hash;
        } else {
          window.location.href = window.loginIp + "/#/ticket?from=" + window.location.hash + '&jl';
        }
        clearTimeout(ti)
      }, 500)

    var timer = setTimeout(() => {
      this.judgingLogin()
      clearTimeout(timer)
    }, 500)
  },
  computed: {
    city: {
      get () {
        return this.$store.state.city
      },
      set (val) {
        this.$store.commit('setCity', val)
      }
    }
  },
  methods: {
    judgingLogin () {
      isLogin().then(res => {
        if (res.data.status === 200) {
          this.$bus.$emit('isLoginType', res.data.data)
          if (!res.data.data) {
            localStorage.removeItem('userInfo')
          } else {
            console.log('ticket--->true', sessionStorage.ticket)
            // 获取用户信息
            userInfo().then(r => {
              if (r.data.status === 200) {
                localStorage.userInfo = JSON.stringify(r.data.data)
                this.$bus.$emit('getUserInfo', r.data.data)
              }
            })
          }
        }
      })
    },
    reload () {
      this.isRouterAlive = false;
      this.$nextTick(function () {
        this.isRouterAlive = true;
      });
    },
    // 初始化地图
    initMap () {
      if (localStorage.city) {
        this.city = localStorage.city
      } else {
        ipLocationCity({ type: 1 }).then(res => {
          if (res.data.status === 200) {
            this.city = res.data.data[0]
          }
        })
      }

    },
    getMap () {
      return new Promise(resolve => {
        let LocalCity = window.BMapGL.LocalCity;
        if (LocalCity) {
          resolve(LocalCity)
        } else {
          let timer = setInterval(() => {
            let LocalCity = window.BMapGL.LocalCity;
            if (LocalCity) {
              resolve(LocalCity)
              clearInterval(timer)
            }
          })
        }
      })
    },
  }
};
</script>

<style>
#app {
  height: 100%;
  min-width: 90rem;
}
</style>
