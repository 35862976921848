import Vue from 'vue';
import VueRouter from 'vue-router';
import Login from '../views/Login.vue';

// 开始添加代码
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err);
};

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    redirect: '/plant'
  },
  {
    path: '/login',
    component: Login
  },
  {
    path: '/plant',
    component: () =>
      import(/* webpackChunkName: "home" */ 'views/plant/Home.vue')
  },
  {
    path: '/my',
    component: () => import(/* webpackChunkName: "home" */ 'views/My.vue')
  },
  // 租厂房-pc端
  {
    path: '/plantRental/:city/:keyword/:districtId/:areaLabelIds/:streetId', // cs 城市 kw 关键字 dr 区县 al 面积 s 街道
    name: 'plantRental',
    component: () =>
      import(/* webpackChunkName: "home" */ 'views/plant/PlantRental.vue')
  },
  // 租厂房-手机端
  {
    path: '/plantRental_m/:city/:keyword/:districtId/:areaLabelIds/:streetId', // cs 城市 kw 关键字 dr 区县 al 面积 s 街道
    name: 'plantRental_m',
    component: () =>
      import(
        /* webpackChunkName: "home" */ 'views/mobile/plant/MPlantRental.vue'
      )
  },
  // 找园区
  {
    path: '/searchGardenArea/:keyword/:districtId', // kw 关键字 dr 区县
    name: 'searchGardenArea',
    component: () =>
      import(/* webpackChunkName: "home" */ 'views/plant/SearchGardenArea.vue')
  },
  // 工厂详情-顾问端路口
  {
    path: '/brokerDetails/:detailId',
    name: 'brokerDetails',
    component: () =>
      import(/* webpackChunkName: "home" */ 'views/plant/BrokerDetails.vue')
  },
  // 工厂详情-管理端端路口
  {
    path: '/adminDetails/:detailId',
    name: 'adminDetails',
    component: () =>
      import(/* webpackChunkName: "home" */ 'views/plant/AdminDetails.vue')
  },
  // 工厂详情
  {
    path: '/plantDetails/:detailId',
    name: 'plantDetails',
    component: () =>
      import(/* webpackChunkName: "home" */ 'views/plant/details.vue')
  },
  // 工厂详情-手机端
  {
    path: '/plantDetails_m/:detailId',
    name: 'plantDetails_m',
    component: () =>
      import(/* webpackChunkName: "home" */ 'views/mobile/plant/MDetails.vue')
  },
  // 园区详情
  {
    path: '/gardenDetails/:detailId',
    name: 'gardenDetails',
    component: () =>
      import(/* webpackChunkName: "home" */ 'views/plant/GardenDetails.vue')
  },
  // 园区找厂房
  {
    path: '/plantRentalInPark/:title/:id',
    name: 'plantRentalInPark',
    component: () =>
      import(/* webpackChunkName: "home" */ 'views/plant/PlantRentalInPark.vue')
  },
  // 顾问主页
  {
    path: '/counselorHome/:id',
    name: 'counselorHome',
    component: () =>
      import(/* webpackChunkName: "home" */ 'views/plant/CounselorHome.vue')
  },
  // 地图找房
  {
    path: '/mapSearch',
    name: 'mapSearch',
    component: () =>
      import(/* webpackChunkName: "home" */ 'views/other/MapSearch.vue')
  },
  // 三维
  {
    path: '/tabModel',
    name: 'tabModel',
    component: () =>
      import(/* webpackChunkName: "home" */ 'views/vr/tabModel.vue'),
    redirect: '/model',
    children: [
      // 全景vr
      // {
      //   path: '/vr/:index/:detailsId',
      //   name: 'vr',
      //   component: () =>
      //     import(/* webpackChunkName: "home" */ 'views/vr/index.vue')
      // },
      {
        path: '/vr/:index/:detailsId',
        name: 'vr',
        component: () =>
          import(/* webpackChunkName: "home" */ 'views/vr/new/index.vue')
      },
      // 园区全景
      {
        path: '/outdoorVr/:index/:detailsId/:vrIndex',
        name: 'outdoorVr',
        component: () =>
          import(/* webpackChunkName: "home" */ 'views/vr/new/outdoorVr.vue')
      },
      // 3d模型-室外
      // {
      //   path: '/model/:index/:detailsId',
      //   name: 'model',
      //   component: () =>
      //     import(/* webpackChunkName: "home" */ 'views/vr/model.vue')
      // },
      {
        path: '/model/:index/:detailsId',
        name: 'model',
        component: () =>
          import(/* webpackChunkName: "home" */ 'views/vr/new/model.vue')
      },
      // 3d模型-室内
      {
        path: '/indoorModel/:index/:detailsId',
        name: 'indoorModel',
        component: () =>
          import(/* webpackChunkName: "home" */ 'views/vr/indoorModel.vue')
      }
    ]
  },

  // -----------------------用户
  // 用户-首页
  {
    path: '/userHome',
    name: 'userHome',
    component: () =>
      import(/* webpackChunkName: "home" */ 'views/user/Home.vue'),
    redirect: '/collectionHousing',
    children: [
      // 收藏房源
      {
        path: '/collectionHousing',
        name: 'collectionHousing',
        component: () =>
          import(
            /* webpackChunkName: "home" */ 'views/user/CollectionHousing.vue'
          )
      },
      // 看房预约
      {
        path: '/lookHouseSubscribe',
        name: 'lookHouseSubscribe',
        component: () =>
          import(
            /* webpackChunkName: "home" */ 'views/user/LookHouseSubscribe.vue'
          )
      },
      // 我的评价
      {
        path: '/myRate',
        name: 'myRate',
        component: () =>
          import(/* webpackChunkName: "home" */ 'views/user/MyRate.vue')
      },
      // 个人信息
      {
        path: '/userInfo',
        name: 'userInfo',
        component: () =>
          import(/* webpackChunkName: "home" */ 'views/user/UserInfo.vue')
      },
      // 我的收益
      {
        path: '/myEarnings',
        name: 'myEarnings',
        component: () =>
          import(/* webpackChunkName: "home" */ 'views/user/MyEarnings.vue')
      }
    ]
  },
  // 合作
  {
    path: '/cooperate',
    name: 'cooperate',
    component: () => import('views/Cooperate.vue')
  },
  // 邀请详情
  {
    path: '/activityDetails',
    name: 'activityDetails',
    component: () => import('views/ActivityDetails.vue')
  },
  {
    path: '/404',
    name: 'four',
    component: () => import('views/404.vue')
  },
  {
    path: '*',
    redirect: '/404'
  },
  // 我的收益-分销协议
  {
    path: '/cashbackAgreement',
    name: 'cashbackAgreement',
    component: () =>
      import(/* webpackChunkName: "home" */ 'views/user/CashbackAgreement.vue')
  }
];

const router = new VueRouter({
  // mode: 'history',
  routes
});

export default router;
